import React, { Component } from 'react'

class Intro extends Component {

    render() {

        return (
            <>
                <div style={{marginTop:'10vh', textAlign:'center'}} className='padding'>
                        <h4 className='intro-h'><b>Hi i'm Matthew. Nice to meet you.</b></h4> 
                        <p className="intro-p left"> 
                        Hard-working software developer, with a passion for problem solving in the least amount of time. 
                        Currently working professionally for the California Department of Technology as a lead React developer. 
                        My primary expertise is in REACT, Redux, JavaScript, Node.js and HTML/CSS. 
                        I am passionate about software development and enjoy what I do.   
                        </p>
                </div>
            </>
        )
    }
}

export default Intro;